import BaseTemplate from "../../Templates/BaseTemplate/BaseTemplate"
import { Defex } from "../../Organisms"

export default function DeficienciesExcesses() {
	return (
		<BaseTemplate>
			<Defex />
		</BaseTemplate>
	)
}
