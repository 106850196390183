import * as S from './styles'
import { useMemo, useState } from 'react'
import { cat, med } from "../../Templates/med"

// Images
import nitrogenio from '../../../assets/defex/pri/nitrogenio.jpeg'
import fosforo from '../../../assets/defex/pri/fosforo.jpeg'
import potassio from '../../../assets/defex/pri/potassio.jpeg'
import calcio from '../../../assets/defex/sec/calcio.jpeg'
import enxofre from '../../../assets/defex/sec/enxofre.jpeg'
import magnesio from '../../../assets/defex/sec/magnesio.jpeg'
import boro from '../../../assets/defex/mic/boro.jpeg'
import cloro from '../../../assets/defex/mic/cloro.jpeg'
import cobre from '../../../assets/defex/mic/cobre.jpeg'
import ferro from '../../../assets/defex/mic/ferro.jpeg'
import manganes from '../../../assets/defex/mic/manganes.jpeg'
import molibdenio from '../../../assets/defex/mic/molibdenio.jpeg'
import zinco from '../../../assets/defex/mic/zinco.jpeg'

const defex: any = {
  "nitrogenio": nitrogenio,
  "fosforo": fosforo,
  "potassio": potassio,
  "calcio": calcio,
  "enxofre": enxofre,
  "magnesio": magnesio,
  "boro": boro,
  "cloro": cloro,
  "cobre": cobre,
  "ferro": ferro,
  "manganes": manganes,
  "molibdenio": molibdenio,
  "zinco": zinco
}

const Defex = () => {
  const [selectedTags, setSelectedTags] = useState<string[]>([])

  const handleClickTag = (tag: any) => {
    setSelectedTags([...selectedTags, tag])
  }

  const DefexCategories = (cat: any) => {
    return (
      <S.MenuStyled>
        {
          cat.map((c: any, ci: number) => (
            <li key={ci}>
              <span>{c.type}</span>
              <ul>
                {
                  c.tags.map((t: any, ti: number) => {
                    if (selectedTags.indexOf(t.slug) >= 0) {
                      return (
                        <li key={ti}>{t.name}</li>
                      )
                    }
                    return (
                      <li key={ti}><button type='button' onClick={() => handleClickTag(t.slug)}>{t.name}</button></li>
                    )
                  })
                }
              </ul>
            </li>
          ))
        }
      </S.MenuStyled>
    )
  }

  const DefexSelected = useMemo(() => {
    return (
      <ul style={{
        display: 'flex',
        gap: '16px',
        listStyle: 'none',
        padding: '0',
        color: '#fff'
      }}>
        {
          selectedTags.map((t: string, ti: number) => {
            return (
              <li key={ti}><button type='button' onClick={() => {
                let tags = [...selectedTags]
                tags.splice(ti, 1)
                setSelectedTags(tags)
              }}>{t}</button></li>
            )
          })
        }
      </ul>
    )
  }, [selectedTags])

  const DefexElements = (med: any) => {
    return (
      <S.ElementsStyled>
        {
          med.map((m: any, mi: number) => {
            const colors = ["#42a5f5", "#ec407a", "#81c784"]

            return (
              <div key={mi} className='columns' style={{ background: colors[mi] + '75' }}>
                <h2>{m.classification}</h2>
                {
                  m.elements.map((e: any, ei: number) => {
                    let hasDefIssue = false
                    let hasExcIssue = false

                    e.deficiency.forEach((d: any) => {
                      const totalFound = d.tag.filter((tag: any) => selectedTags.includes(tag))
                      if (totalFound.length) hasDefIssue = true
                    })

                    e.excess.forEach((x: any) => {
                      const totalFound = x.tag.filter((tag: any) => selectedTags.includes(tag))
                      if (totalFound.length) hasExcIssue = true
                    })

                    return (
                      <div key={ei} className='rows'>
                        {
                          (hasDefIssue || hasExcIssue) ? (
                            <>
                              <h3 style={{ background: colors[mi] }}>{`${e.element} (${e.simbol})`}</h3>
                              <div className='block'>
                                <div style={{
                                  textAlign: 'right',
                                  opacity: hasExcIssue ? '1' : '0.25'
                                }}>
                                  <h4>Excesso</h4>
                                  <ul>
                                    {
                                      e.excess.map((x: any, xi: number) => {
                                        const totalFound = x.tag.filter((tag: any) => selectedTags.includes(tag))
                                        return (
                                          <li key={xi}>
                                            {
                                              totalFound.length ? (
                                                <strong style={{ color: colors[mi] }}>{x.message}</strong>
                                              ) : (
                                                <span>{x.message}</span>
                                              )
                                            }
                                          </li>
                                        )
                                      })
                                    }
                                  </ul>
                                </div>

                                <div className='image'>
                                  <img src={defex[e.image]} alt={e.element} width={'100%'} />
                                </div>

                                <div style={{
                                  opacity: hasDefIssue ? '1' : '0.25'
                                }}>
                                  <h4>Deficiência</h4>
                                  <ul>
                                    {
                                      e.deficiency.map((d: any, di: number) => {
                                        const totalFound = d.tag.filter((tag: any) => selectedTags.includes(tag))
                                        return (
                                          <li key={di}>
                                            {
                                              totalFound.length ? (
                                                <strong style={{ color: colors[mi] }}>{d.message}</strong>
                                              ) : (
                                                <span>{d.message}</span>
                                              )
                                            }
                                          </li>
                                        )
                                      })
                                    }
                                  </ul>
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )
                        }
                      </div>
                    )
                  })
                }
              </div>
            )
          })
        }
      </S.ElementsStyled>
    )
  }

  return (
    <S.DefexStyled>
      {DefexCategories(cat)}

      {DefexSelected}

      {DefexElements(med)}
    </S.DefexStyled>
  )
}

export default Defex
