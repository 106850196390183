export const cat = [
  {
    type: 'Folhas',
    tags: [
      {
        slug: "folha-amarela",
        name: "Folhas amareladas"
      },
      {
        slug: "folha-verde-escura",
        name: "Folhas verde escura"
      },
      {
        slug: "folha-verde-azulada",
        name: "Folhas verde azulada"
      },
      {
        slug: "folha-morrom-amarelada",
        name: "Folhas morrom amarelada"
      },
      {
        slug: "folha-marrom-ferrugem",
        name: "Folhas marrom ferrugem"
      },
      {
        slug: "folha-verde-limao",
        name: "Folhas verde limão"
      },
      {
        slug: "folha-roxa-cobre",
        name: "Folhas roxa cobre"
      },
      {
        slug: "folha-roxa-metalica",
        name: "Folhas roxa metalica"
      },
      {
        slug: "folha-sem-brilho",
        name: "Folha sem brilho"
      },
      {
        slug: "folha-enrola",
        name: "Folhas enrolam"
      },
      {
        slug: "folha-cai",
        name: "Folhas caem"
      },
      {
        slug: "folha-menor",
        name: "Folhas menores"
      },
      {
        slug: "folha-fraca",
        name: "Folhas fracas"
      },
      {
        slug: "folhas-finas",
        name: "Folhas finas"
      },
      {
        slug: "folha-queima",
        name: "Folhas queimadas"
      },
      {
        slug: "folha-inferior-enrola",
        name: "Folhas inferiores enrolam"
      },
      {
        slug: "folha-clorose",
        name: "Folhas clorose"
      },
      {
        slug: "folha-enferrujada",
        name: "Folhas enferrujadas"
      },
      {
        slug: "folha-murcha",
        name: "Folhas murcham"
      }
    ]
  },
  {
    type: 'Caule',
    tags: [
      {
        slug: "caule-fraco",
        name: "Caule fraco"
      },
      {
        slug: "caule-listra",
        name: "Caule com listras"
      },
      {
        slug: "caule-lenhoso",
        name: "Caule lenhoso"
      }
    ]
  },
  {
    type: 'Gosto',
    tags: [
      {
        slug: "gosto-verde",
        name: "Gosto verde (de mato)"
      },
      {
        slug: "gosto-quimico",
        name: "Gosto químico"
      }
    ]
  },
  {
    type: 'Raízes',
    tags: [
      {
        slug: "raiz-morre",
        name: "Raíz morre"
      },
      {
        slug: "raiz-acida",
        name: "Raíz ácida"
      }

    ]
  },
  {
    type: 'Química',
    tags: [
      {
        slug: "transporte-agua-fraco",
        name: "Transporte de água fraco"
      },
      {
        slug: "absorcao-potassio-bloqueada",
        name: "Absorção de potássio bloqueada"
      },
      {
        slug: "deficiencia-zinco",
        name: "Deficiência de zinco"
      },
      {
        slug: "deficiencia-calcio",
        name: "Deficiência de cálcio"
      },
      {
        slug: "toxicidade-salina",
        name: "Toxicidade salina"
      },
      {
        slug: "clorose-internerval",
        name: "Clorose internerval"
      }
    ]
  },
  {
    type: 'Desenvolvimento',
    tags: [
      {
        slug: "planta-fraca",
        name: "Planta fraca"
      },
      {
        slug: "baixo-rendimento",
        name: "Baixo rendimento"
      },
      {
        slug: "baixo-desenvolvimento",
        name: "Baixo desenvolvimento"
      },
      {
        slug: "crescimento-diminui",
        name: "Crescimento diminuído"
      },
      {
        slug: "desenvolvimento-lento",
        name: "Desenvolvimento lento"
      },
      {
        slug: "maior-ramificacao",
        name: "Maior ramificação"
      }
    ]
  },
  {
    type: 'Outras',
    tags: [
      {
        slug: "peciolo-roxo",
        name: "Peciolo roxo"
      },
      {
        slug: "menor-espaco",
        name: "Menor espaço"
      },
      {
        slug: "menor-espaco-intermodal",
        name: "Menor espaço intermodal"
      },
      {
        slug: "aparencia-doentia",
        name: "Aparência doentia"
      }
    ]
  }
]

export const med = [
  {
    classification: "Nutrientes Primários",
    elements: [
      {
        element: "Nitrogênio",
        simbol: "N",
        image: "nitrogenio",
        deficiency: [
          {
            tag: ["folha-amarela"],
            message: "As folhas inferiores ficam amarelas"
          },
          {
            tag: ["folha-sem-brilho"],
            message: "As folhas perdem o brilho"
          },
          {
            tag: ["folha-amarela"],
            message: "O amarelamento progride para cima"
          },
          {
            tag: ["folha-amarela", "folha-enrola"],
            message: "As folhas continuam a amarelar, enrolar e descolorir"
          },
          {
            tag: ["folha-cai"],
            message: "As folhas começam a cair"
          },
          {
            tag: ["folha-menor"],
            message: "As plantas são mais curtas e com folhas menores"
          },
          {
            tag: ["baixo-rendimento"],
            message: "Floração prematura e baixo rendimento"
          }
        ],
        excess: [
          {
            tag: ["folha-verde-escura"],
            message: "As folhas inferiores ficam verde-escuras exuberantes"
          },
          {
            tag: ["folha-verde-escura"],
            message: "O verde sobe"
          },
          {
            tag: ["folha-fraca"],
            message: "A folhagem fica fraca"
          },
          {
            tag: ["caule-fraco"],
            message: "Os caules ficam fracos"
          },
          {
            tag: ["transporte-agua-fraco"],
            message: "O sistema de transporte de água/fluidos torna-se fraco"
          },
          {
            tag: ["gosto-verde"],
            message: "A colheita tem gosto verde"
          }
        ]
      },
      {
        element: "Fósforo",
        simbol: "P",
        image: "fosforo",
        deficiency: [
          {
            tag: ["folha-verde-azulada"],
            message: "As folhas ficam verde-azuladas"
          },
          {
            tag: ["peciolo-roxo"],
            message: "Os pecíolos ficam com uma cor arroxeada"
          },
          {
            tag: ["crescimento-diminui"],
            message: "O crescimento vertical e lateral diminui"
          },
          {
            tag: ["folha-roxa-cobre"],
            message: "Manchas mortas de cor cobre escura ou roxa a enegrecida nas folhas inferiores"
          },
          {
            tag: ["folha-roxa-metalica"],
            message: "Folhas gravemente afetadas desenvolvem folhas roxas metálicas bronzeadas escuras que continuam a enrolar, contorcer-se, murchar e cair"
          },
          {
            tag: ["planta-fraca"],
            message: "Planta fraca, suscetível a doenças e pragas"
          }
        ],
        excess: [
          {
            tag: ["deficiencia-zinco"],
            message: "Deficiências de micronutrientes de zinco e ferro"
          },
          {
            tag: ["clorose-internerval"],
            message: "Folhas mais novas desenvolvem clorose internerval"
          },
          {
            tag: ["folhas-finas"],
            message: "Folhas novas crescem em lâminas finas"
          },
          {
            tag: ["folha-queima"],
            message: "Pontas e margens das folhas queimam"
          },
          {
            tag: ["menor-espaco"],
            message: "Menos espaço intermodal"
          },
          {
            tag: ["baixo-rendimento"],
            message: "Colheita diminuída"
          },
          {
            tag: ["deficiencia-calcio"],
            message: "Aparecem deficiências de cálcio e magnésio"
          },
          {
            tag: ["folha-inferior-enrola"],
            message: "As folhas inferiores enrolam-se, desenvolvem manchas"
          },
          {
            tag: ["raiz-morre"],
            message: "As pontas das raízes morrem"
          },
          {
            tag: ["gosto-quimico"],
            message: "Os botões secos têm sabor químico"
          }
        ]
      },
      {
        element: "Potássio",
        simbol: "K",
        image: "potassio",
        deficiency: [
          {
            tag: ["folha-clorose"],
            message: "As folhas mais velhas ficam pálidas e sofrem clorose"
          },
          {
            tag: ["folha-enferrujada"],
            message: "As margens e pontas das folhas ficam enferrujadas e queimam"
          },
          {
            tag: ["caule-fraco"],
            message: "Os caules muitas vezes ficam fracos, magros e às vezes quebradiços"
          },
          {
            tag: ["maior-ramificacao"],
            message: "A ramificação do caule pode aumentar"
          },
          {
            tag: ["baixo-rendimento"],
            message: "Floração retardada e diminuída"
          }
        ],
        excess: [
          {
            tag: ["raiz-acida"],
            message: "A zona radicular é acidificada"
          },
          {
            tag: ["deficiencia-calcio"],
            message: "Causa deficiências de cálcio, magnésio, zinco e ferro"
          },
          {
            tag: ["folha-clorose"],
            message: "Folhas mais novas desenvolvem clorose internerval"
          },
          {
            tag: ["folha-finas"],
            message: "Folhas novas crescem em lâminas finas"
          },
          {
            tag: ["folha-queima"],
            message: "Pontas e margens das folhas queimam"
          },
          {
            tag: ["menor-espaco-intermodal"],
            message: "Menos espaço intermodal"
          },
          {
            tag: ["folha-enrola"],
            message: "As folhas inferiores enrolam-se, desenvolvem manchas"
          },
          {
            tag: ["raiz-morre"],
            message: "As pontas das raízes morrem"
          }
        ]
      }
    ]
  },
  {
    classification: "Nutrientes Secundários",
    elements: [
      {
        element: "Cálcio",
        simbol: "Ca",
        image: "calcio",
        deficiency: [
          {
            tag: ["folha-enrola"],
            message: "As folhas inferiores se contorcem e enrolam"
          },
          {
            tag: ["folha-morrom-amarelada"],
            message: "Manchas irregulares marrom-amareladas se desenvolvem nas folhas e margens"
          },
          {
            tag: ["desenvolvimento-lento"],
            message: "O desenvolvimento das flores é lento"
          },
          {
            tag: ["raiz-morre"],
            message: "As pontas das raízes podem morrer"
          },
          {
            tag: ["baixo-rendimento"],
            message: "Planta atrofiada, colheita diminuída"
          }
        ],
        excess: [
          {
            tag: ["folha-murcha"],
            message: "Pequena murcha das folhas"
          },
          {
            tag: ["baixo-desenvolvimento"],
            message: "O crescimento pode ser atrofiado"
          },
          {
            tag: ["absorcao-potassio-bloqueada"],
            message: "Absorção de potássio, ferro, magnésio e manganês bloqueada"
          }
        ]
      },
      {
        element: "Magnésio",
        simbol: "Mg",
        image: "magnesio",
        deficiency: [
          {
            tag: [],
            message: "As deficiências existem 4 - 6 semanas antes que os sinais externos sejam aparentes"
          },
          {
            tag: ["folha-marrom-ferrugem"],
            message: "Amarelelamento internerval e manchas marrom-ferrugem irregulares aparecem nas folhas mais velhas e de meia-idade"
          },
          {
            tag: ["aparencia-doentia"],
            message: "Aparência geral doentia"
          },
          {
            tag: ["folha-cai"],
            message: "As folhas mais velhas secam, muitas vezes enrolam e caem"
          }
        ],
        excess: [
          {
            tag: ["baixo-desenvolvimento"],
            message: "Crescimento atrofiado"
          },
          {
            tag: ["folha-verde-escura"],
            message: "Folhagem verde escura"
          },
          {
            tag: ["toxicidade-salina"],
            message: "Os sintomas aparecem como toxicidade salina geral"
          }
        ]
      },
      {
        element: "Enxofre",
        simbol: "S",
        image: "enxofre",
        deficiency: [
          {
            tag: ["folha-verde-limao"],
            message: "As folhas novas tornam-se verde-limão a amareladas e o crescimento é atrofiado"
          },
          {
            tag: ["folha-amarela"],
            message: "À medida que a escassez avança, as nervuras das folhas ficam amareladas e perdem suculência"
          },
          {
            tag: ["folha-queima"],
            message: "As pontas das folhas podem queimar, escurecer e ficar presas para baixo"
          },
          {
            tag: ["caule-listra"],
            message: "Longas listras roxas podem aparecer no comprimento do caule quando combinadas com uma deficiência geral de nutrientes"
          },
          {
            tag: ["caule-lenhoso"],
            message: "Os caules muitas vezes ficam lenhosos"
          },
          {
            tag: ["desenvolvimento-lento"],
            message: "A formação de botões é lenta e fraca"
          }
        ],
        excess: [
          {
            tag: ["folha-verde-escura"],
            message: "Desenvolvimento geral da planta menor e folhagem verde-escura uniformemente menor"
          },
          {
            tag: ["folha-queima"],
            message: "As pontas e margens das folhas podem descolorir e queimar quando o excesso é severo"
          }
        ]
      }
    ]
  },
  {
    classification: "Micronutrientes",
    elements: [
      {
        element: "Boro",
        simbol: "B",
        image: "boro",
        deficiency: [
          {
            tag: [],
            message: "As folhas inferiores se contorcem e enrolam"
          }
        ],
        excess: [
          {
            tag: [],
            message: "Pequena murcha das folhas"
          }
        ]
      },
      {
        element: "Manganes",
        simbol: "Mn",
        image: "manganes",
        deficiency: [
          {
            tag: [],
            message: "As deficiências existem 4 - 6 semanas antes que os sinais externos sejam aparentes"
          }
        ],
        excess: [
          {
            tag: [],
            message: "Crescimento atrofiado"
          }
        ]
      },
      {
        element: "Zinco",
        simbol: "Zn",
        image: "zinco",
        deficiency: [
          {
            tag: [],
            message: "As folhas novas tornam-se verde-limão a amareladas e o crescimento é atrofiado"
          }
        ],
        excess: [
          {
            tag: [],
            message: "Desenvolvimento geral da planta menor e folhagem verde-escura uniformemente menor"
          }
        ]
      }
    ]
  }
]