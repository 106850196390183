import styled from 'styled-components'
import media from 'styled-media-query'

export const DefexStyled = styled.div`
	${media.lessThan('medium')`
	`}
`

export const MenuStyled = styled.ul`
	&& {
		display: flex;
		list-style: none;
		flex-wrap: wrap;
		gap: 8px;
		padding: 0;
		margin: 1rem;
		position: relative;
		z-index: 20;
	
		${media.lessThan('medium')`
		`}

		&:hover {
			& > li {
				span {
					opacity: 0.5;
				}
			}
		}
	
		& > li {
			cursor: pointer;
			position: relative;

			& > span {
				background: ${({ theme }) => theme.colors.main};
				color: ${({ theme }) => theme.colors.text.third};
				font-size: 13px;
				padding: 0.5rem;
				display: flex;
				// opacity: 0.5;
			}
			
			& > ul {
				background: ${({ theme }) => theme.colors.main};
				color: ${({ theme }) => theme.colors.text.third};
				font-size: 13px;
				padding: 0.5rem;
				display: none;
				list-style: none;
				padding: 0;
				position: absolute;
	
				& > li {
					padding: 0.25rem;
					cursor: pointer;
					white-space: nowrap;
				}
			}

			&:hover {
				& > span {
					opacity: 1;
				}
				
				& > ul {
					display: flex;
					flex-direction: column;
				}
			}
		}
	}
`

export const ElementsStyled = styled.div`
	&& {
		display: flex;
		gap: 8px;
		width: 100%;

		.columns {
			padding: 0.5rem;
			flex: 1;
		}

		.rows {
			background: #ffffff;
			padding: 0.5rem;
		}

		h3 {
			color: '#ffffff',
			margin: '0 0 1rem',
			padding: '0.25rem'
		}

		h4 {
			margin: 0 0 0.5rem;
		}

		.block {
			align-items: stretch;
			display: grid;
			grid-template-columns: 33% 34% 33%;

			.image {
				display: flex;
				align-items: center;
			}

			ul {
				display: flex;
				flex-direction: column;
				gap: 16px;
				list-style: none;
				padding: 0;
				font-size: 12px;
				line-height: 14px;
			}
		}
	}
`