/* eslint-disable react/jsx-no-comment-textnodes */
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { DeficienciesExcesses, HomeLoggedIn, HomeLoggedOut } from './components/Pages'
import { useContext } from 'react'
import { MainContext } from './context/MainContext/MainContext'

function AppRoutes() {
	const { login } = useContext(MainContext)

	return (
		<BrowserRouter>
			<Routes>
				<Route path='/defex' element={<DeficienciesExcesses />}></Route>
				{
					Object.keys(login).length ? (
						login.isLoggedIn ? (
							<Route index element={<HomeLoggedIn />}></Route>
						) : (
							<Route index element={<HomeLoggedOut />}></Route>
						)
					) : (
						<Route index element={<></>}></Route>
					)
				}
			</Routes>
		</BrowserRouter>
	)
}
export default AppRoutes
